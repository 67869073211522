import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import PdfGenerator from '../../components/PdfGenerator';
import { CSVLink } from 'react-csv';

const DateWisePackingReport = () => {
  const [receiptNo, setReceiptNo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date()));
  const [to, setTo] = useState(dateFormat(new Date()));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
  });
  const filterColumns: Array<String> = [
    'Bale_count',
    'Cotton_Bale_Count',
    'Cotton_LMts',
    'LMts',
    'MMF_Bale_Count',
    'MMF_LMts',
    'date',
  ];
  const getLocaleNumber = (num) => {
    if (num === 0) {
      return '-';
    }
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'Date',
      selector: (row) => `${new Date(row.date).toLocaleDateString('en-IN')}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Cotton Bales',
      selector: (row) => `${row.Cotton_Bale_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Cotton L.Mts',
      selector: (row) => `${row.Cotton_LMts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'MMF Bales',
      selector: (row) => `${row.MMF_Bale_Count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'MMF L.Mts',
      selector: (row) => `${row.MMF_LMts}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total Bales',
      selector: (row) => `${row.Bale_count}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Total L.Mts',
      selector: (row) => `${row.LMts}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const pdfTableTitles = [
    'Date',
    'Cotton Bales',
    'Cotton L.Mts',
    'MMF Bales',
    'MMF L.Mts',
    'Total Bales',
    'Total L.Mts',
  ];
  const getApi = async () => {
    showLoader();
    let res = await axios.get(`${baseUri()}grey-receipt-advice/datewise-packing-report?from=${from}&to=${to}`);
    res = (await res.data.data?.result) || [];
    const pdfTableContents: Array<any> = [];
    const totalContents = {
      Date: 0,
      'Cotton Bales': 0,
      'Cotton L.Mts': 0,
      'MMF Bales': 0,
      'MMF L.Mts': 0,
      'Total Bales': 0,
      'Total L.Mts': 0,
    };
    res.forEach((row) => {
      pdfTableContents.push([
        new Date(row.date).toLocaleDateString('en-IN'),
        getLocaleNumber(row.Cotton_Bale_Count),
        getLocaleNumber(row.Cotton_LMts),
        getLocaleNumber(row.MMF_Bale_Count),
        getLocaleNumber(row.MMF_LMts),
        getLocaleNumber(row.Bale_count),
        getLocaleNumber(row.LMts),
      ]);
      totalContents['Cotton Bales'] += +row.Cotton_Bale_Count;
      totalContents['Cotton L.Mts'] += +row.Cotton_LMts;
      totalContents['MMF Bales'] += +row.MMF_Bale_Count;
      totalContents['MMF L.Mts'] += +row.MMF_LMts;
      totalContents['Total Bales'] += +row.Bale_count;
      totalContents['Total L.Mts'] += +row.LMts;
    });
    pdfTableContents.push([
      'Total',
      getLocaleNumber(totalContents['Cotton Bales']),
      getLocaleNumber(totalContents['Cotton L.Mts']),
      getLocaleNumber(totalContents['MMF Bales']),
      getLocaleNumber(totalContents['MMF L.Mts']),
      getLocaleNumber(totalContents['Total Bales']),
      getLocaleNumber(totalContents['Total L.Mts']),
    ]);
    setPdfData({
      from: new Date(from).toLocaleDateString('en-IN'),
      to: new Date(to).toLocaleDateString('en-IN'),
      tableTitles: pdfTableTitles,
      tableContents: pdfTableContents,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="Datewise Packing Report" />
      <Card>
        <header>Datewise Packing Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                From Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="from"
                  value={from}
                  placeholder="From Date"
                  onChange={(e) => setFrom(e.target.value)}
                  className="from require"
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                To Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="to"
                  value={to}
                  placeholder="To Date"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>Datewise Packing Report</header>
        <CardBody>
          {tableData.length > 0 && (
            <p>
              <PdfGenerator documentTitle="datewise-packing-report" data={pdfData} />{' '}
              <CSVLink data={tableData}>Download Excel</CSVLink>
            </p>
          )}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default DateWisePackingReport;
